<template>
  <main class="models-page">
    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg:col-5 xl:col-3">
            <mega-card>
              <div class="card-body">
                <mega-drop-zone
                  @change="renderFile"
                  :type="['image/jpg', 'image/jpeg', 'image/png']"
                >
                  <mega-image ratio="1x1" :src="''" v-if="!preview" />
                  <img
                    class="w-100"
                    alt=""
                    style="transition: .1s"
                    :src="preview"
                    v-if="preview"
                    :class="{ 'opacity-0': rendering }"
                  />
                </mega-drop-zone>
              </div>

              <div class="card-body">
                <mega-input :label="$t('img_name')" v-model="model.name" />
              </div>

              <ul class="menu-list">
                <li class="menu-item">
                  <mega-switch
                    class="w-100 mb-0 bg-success"
                    :label="$t('img_show')"
                    @click="toggleActive"
                    :checked="model['is_active']"
                  />
                </li>
              </ul>

              <div class="card-body">
                <mega-button
                  class="btn w-100 btn-success"
                  :loading="loading"
                  @click="submit"
                  >{{ $t("model_save") }}</mega-button
                >
              </div>
            </mega-card>

            <mega-card>
              <div class="card-body" style="overflow: hidden;">
                <mega-image ratio="1x1" :src="qr_image" save-as-img />
              </div>
            </mega-card>
          </div>

          <div class="col">
            <nav
              class="nav sm-down:flex-column mb-3"
              v-if="model['modifications'] && model['modifications'].length > 0"
            >
              <span
                class="btn btn-sm text-left d-block sm-down:w-fill"
                :class="{
                  'btn-primary':
                    modification.idt_model_modif === modif.idt_model_modif
                }"
                v-for="(modif, i) in model['modifications']"
                @click="openModif(i)"
                :key="i"
                >{{ modif.name || "- - -" }}</span
              >
            </nav>

            <mega-card :title="$t('img_vis')">
              <div class="card-body">
                <ul class="menu-list">
                  <li class="menu-item">
                    <mega-switch
                      class="w-100 mb-0 bg-success"
                      :label="$t('model_vis_ios')"
                      :class="{
                        'text-muted events-disable': !modification.image
                      }"
                      @click="changeModif('is_ios', !modification['is_ios'])"
                      :checked="modification['is_ios']"
                    />
                  </li>
                  <li class="menu-item">
                    <mega-switch
                      class="w-100 mb-0 bg-success"
                      :label="$t('model_vis_android')"
                      :class="{
                        'text-muted events-disable': !modification.image
                      }"
                      @click="
                        changeModif('is_android', !modification['is_android'])
                      "
                      :checked="modification['is_android']"
                    />
                  </li>
                </ul>
              </div>
            </mega-card>

            <mega-card
              :title="$t('img_imgs')"
              :menu="[{ icon: 'i-plus', click: addImage }]"
            >
              <div class="card-body">
                <ul class="menu-list">
                  <li
                    class="menu-item cursor-pointer"
                    @click="editImage(img['idt_ref_image'])"
                    v-for="(img, i) in ref_images"
                    :key="i"
                  >
                    <div class="d-flex flex-column">
                      <span class="form-label"
                        >ID: {{ img.idt_ref_image }}</span
                      >
                      <span class="mr-auto form-label">{{ img.name }}</span>
                    </div>

                    <div style="width: 40px;">
                      <mega-image ratio="1x1" small :src="img.url" />
                    </div>
                  </li>
                </ul>
              </div>
            </mega-card>
          </div>
        </div>
      </div>
    </section>

    <new-image
      ref="new-image"
      :id="model_id"
      :mod_id="modification_id"
      @close="getModel"
    />
    <edit-image ref="edit-image" @close="getModel" />
  </main>
</template>

<script>
import NewImage from "../../components/new_image";
import EditImage from "../../components/edit_image";
import QrCodeWithLogo from "qr-code-with-logo";

export default {
  name: "ModelsPage",
  props: ["model_id", "modification_id"],
  data() {
    return {
      model: {},
      modification: {},
      ref_images: [],
      gif: undefined,
      preview: null,
      loading: true,
      rendering: false,
      loaded: false,
      qr_image: undefined,
      qr_code: undefined
    };
  },
  mounted() {
    this.$navbar.name = "Image";

    this.buildQrCanvas();
    this.getModel();
  },
  methods: {
    buildQrCanvas() {
      this.qr_code = document.createElement("canvas");
      this.qr_code.width = 1000;
      this.qr_code.height = 1000;
      this.qr_code.style.top = "-9999999px";
      this.qr_code.style.left = "-9999999px";
      this.qr_code.style.position = "absolute";
      this.qr_code.style.pointerEvents = "none";
      this.qr_code.style.visibility = "hidden";
      this.qr_code.style.imageRendering = "pixelated";
      this.qr_code.style.imageRendering = "crisp-edges";

      document.body.appendChild(this.qr_code);
    },

    addImage() {
      if (window && window.isMobileOrTablet())
        this.$router.push(
          `/ref_image/new/${this.model_id}/${this.modification_id}`
        );
      else this.$refs["new-image"].open();
    },

    editImage(id) {
      if (window && window.isMobileOrTablet())
        this.$router.push("/ref_image/" + id);
      else this.$refs["edit-image"].open(id);
    },

    toggleActive() {
      let active = !this.model["is_active"];

      let data = {
        idt_model: this.model["idt_model"],
        is_active: active
      };

      this.changeModif("is_active", active);

      this.$api.v2
        .put("/model", data)
        .then(response => {
          this.model = response.data;
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    change(prop, value) {
      let data = {
        idt_model: this.model["idt_model"]
      };

      data[prop] = value;

      this.$api.v2.put("/model", data).then(response => {
        this.model[prop] = response.data[prop];
      });
    },

    changeModif(prop, value) {
      let formData = new FormData();
      formData.append("idt_model_modif", this.modification["idt_model_modif"]);
      formData.append(prop, value);

      this.$api.v2
        .put("/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          this.modification = response.data["modif"];
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    openModif(i) {
      let modif = this.model.modifications[i] || [];

      if (modif) {
        if (modif.image) {
          this.$render(modif["image"]["url"]).then(src => {
            this.preview = src;
          });
        }

        let nodeQrCodeOptions = {
            margin: 0,
            color: {
              dark: "#5cc8c8",
              light: "#0000"
            }
          },
          logo = {
            src: require("../../../assets/logo-sphere.svg"),
            borderRadius: 0,
            logoSize: 0.2,
            radius: 0
          };

        QrCodeWithLogo.toCanvas({
          canvas: this.qr_code,
          content: "https://arround.world/?qr=" + modif["idt_model_modif"],
          width: 500,
          nodeQrCodeOptions,
          logo
        }).then(() => {
          this.qr_image = this.qr_code.toDataURL();

          this.qr_code.removeAttribute("style");
          this.qr_code.width = 1000;
          this.qr_code.height = 1000;
          this.qr_code.style.top = "-9999999px";
          this.qr_code.style.left = "-9999999px";
          this.qr_code.style.position = "absolute";
          this.qr_code.style.pointerEvents = "none";
          this.qr_code.style.visibility = "hidden";
          this.qr_code.style.imageRendering = "pixelated";
          this.qr_code.style.imageRendering = "crisp-edges";
        });

        this.modification = modif;
      }
    },

    renderFile(file) {
      this.loading = true;

      let formData = new FormData();
      formData.append("idt_model_modif", this.modification["idt_model_modif"]);
      formData.append("the_image", file);

      this.$api.v2
        .put("/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(({ data }) => {
          this.loading = false;

          this.$alert.success("Preview was updated");

          this.modification["image"] = data.modif.image;

          this.$render(this.modification["image"]["url"]).then(src => {
            this.preview = src;
          });
        })
        .catch(err => console.log(err));
    },

    getModel() {
      this.$api.v2
        .get("/model", { params: { idt_model: this.model_id } })
        .then(response => {
          this.model = response.data;

          if (
            this.model.modifications.filter(
              modif => modif.idt_model_modif === Number(this.modification_id)
            ).length === 0
          ) {
            throw "This modification do not exist!";
          }

          this.loaded = true;
          this.loading = false;

          this.ref_images = this.model.modifications[0]["ref_images"];

          this.$navbar.name = `${this.$t("img_title")} #${
            this.model.idt_model
          } | ${this.model.name}`;

          this.openModif(0);
        })
        .catch(err => {
          this.$router.replace("/404");
        });
    },

    submit() {
      this.loading = true;

      let data = {
        idt_model: this.model.idt_model,
        idt_group: this.model.idt_group,
        name: this.model.name,
        modifications: this.modification
      };

      this.$api.v2
        .put("/model", data)
        .then(() => {
          this.loading = false;
          this.$alert.success("Data was updated");
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    }
  },
  components: {
    EditImage,
    NewImage
  }
};
</script>
